import Phaser from "phaser"
import { Vector2 } from "three"

export class LastPage extends Phaser.Scene {
  constructor() {
    super("LastPage")
    this._score = 0
    this._name = "Anonymous"
  }

  init() {}

  preload() {
    // load font
    this.load.script("webfont", "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js")
  }

  create() {
    this.redbg = this.add.image(this.game.config.width * 0.5, this.game.config.height * 0.5, "redbg").setOrigin(0.5)
    this.redbg.setDisplaySize(this.game.config.width, this.game.config.height)
    this.redbg.setDepth(-100)

    if (this.game.config.width > this.game.config.height) {
      this.logo = this.add
        .sprite(this.game.config.height * 0.1, this.game.config.height * 0.07, "logo")
        .setDisplaySize(this.game.config.height * 0.15, this.game.config.height * 0.15)
    } else {
      this.logo = this.add
        .sprite(this.game.config.width * 0.1, this.game.config.height * 0.04, "logo")
        .setDisplaySize(this.game.config.width * 0.15, this.game.config.width * 0.15)
    }

    // this.logoLanding = this.add
    //   .image(this.cup.x + this.game.config.height * 0.4 * 0.36, this.cup.y + this.game.config.height * 0.4 * 0.55, "logolanding")
    //   .setOrigin(0.5)
    // this.logoLanding.setDisplaySize(this.game.config.height * 0.08 * 1.65, this.game.config.height * 0.08)

    this.landingVideo = this.add.sprite(
      this.game.config.width * 0.5,
      this.game.config.height * 0.39 + (this.game.config.height * 0.32 * 5 * 0.5) / 25,
      "Front_Anim_00000",
    )
    this.landingVideo.setDisplaySize(this.game.config.height * 0.32, this.game.config.height * 0.32)

    this.landingVideo.anims.play("landingvideo").on("animationcomplete", () => {
      this.landingVideo.anims.play("closeEye")
    })

    // draw rectangle

    this.cup = this.add.image(this.landingVideo.x, this.game.config.height * 0.39 + ((this.game.config.height * 0.32 * 2.6) / 10) * 0.5, "cup").setOrigin(0.5)
    this.cup.setDisplaySize(this.game.config.height * 0.32, (this.game.config.height * 0.32 * 13) / 10)

    // this.light = this.add.image(this.cup.x - this.game.config.height * 0.35 * 0.45, this.cup.y - this.game.config.height * 0.35 * 0.6, "light").setOrigin(0.5)
    // this.light.setDisplaySize(this.game.config.height * 0.06 * 1.65, this.game.config.height * 0.06)
    // this.light.setRotation(Math.PI)

    this.scoreBell = this.add
      .image(
        this.cup.x - this.game.config.height * 0.35 * 0.3,
        this.cup.y + this.game.config.height * 0.35 * 0.4 - ((this.game.config.height * 0.32 * 2.6) / 10) * 0.5,
        "bellball",
      )
      .setOrigin(0.5)

    this.scoreBell.setDisplaySize(this.game.config.height * 0.11, this.game.config.height * 0.11)

    window.WebFont.load({
      custom: {
        families: ["HelveticaLTPro-Bold", "Beloved"],
      },
      active: () => {
        let name = this._name
        // first letter uppercase
        name = name[0].toUpperCase() + name.slice(1)
        this.text = this.add
          .text(this.cup.x, this.cup.y - this.game.config.height * 0.35 * 0.47 - ((this.game.config.height * 0.32 * 2.6) / 10) * 0.5, name, {
            fontFamily: "Beloved",
            fontSize: this.game.config.height * 0.06,
            color: "#ffffff",
            align: "center",
            padding: {
              left: this.game.config.height * 0.08,
              right: this.game.config.height * 0.08,
              top: this.game.config.height * 0.08,
              bottom: this.game.config.height * 0.08,
            },
          })
          .setOrigin(0.5)
          .setDepth(10000)

        // let fx = this.text.preFX.addGlow(0xffffff)
        // fx.outerStrength = 1
        // this.tweens.add({
        //   targets: fx,
        //   outerStrength: 1.5,
        //   yoyo: true,
        //   loop: -1,
        //   ease: "sine.inout",
        // })

        this.text.setRotation(Math.PI * -0.04)

        this.scoreText = this.add
          .text(this.scoreBell.x + this.game.config.height * 0.11 * 0.05, this.scoreBell.y * 0.97, "score", {
            fontFamily: "HelveticaLTPro-Bold",
            fontSize: this.game.config.height * 0.023,
            color: "#ffffff",
            align: "center",
          })
          .setOrigin(0.5)

        this.score = this.add
          .text(this.scoreBell.x + this.game.config.height * 0.11 * 0.05, this.scoreText.y + this.scoreText.height * 1.1, this._score, {
            fontFamily: "HelveticaLTPro-Bold",
            fontSize: this.game.config.height * 0.025,
            color: "#ffffff",
            align: "center",
          })
          .setOrigin(0.5)
      },
    })
  }

  update() {}
}
