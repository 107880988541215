import React, { useEffect } from "react"
import { THREECANVAS } from "./3d/3dcanvas"
import IoPhaser from "./2d/phaser"
import Overlay, { Preload } from "./overlay"
import { DesktopBlock } from "./overlay/components/index"
import { BrowserView, MobileView } from "react-device-detect"

function App() {
  const ref = React.useRef(null)

  return (
    <div className="App">
      <BrowserView>
        <DesktopBlock />
      </BrowserView>

      <MobileView>
        <Preload />
        <Overlay phaserRef={ref} />
        <THREECANVAS phaserRef={ref} />
        <IoPhaser ref={ref} />
      </MobileView>
    </div>
  )
}

export default App
