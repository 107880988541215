import Phaser from "phaser"
import { Vector2 } from "three"
import { useStore } from "../store/index.js"
import { Flake } from "./flake.js"
export class Game extends Phaser.Scene {
  constructor() {
    super("game")
    this.emitter = null
    this.dpr = window.devicePixelRatio
    this.currentTime = 0
    this.count = {
      value: 0,
    }
    this.particlegroup = []
    this.playParticleSpin = false
    this.circularProgress = null

    // img
    this.super_1 = null
    this.super_2 = null
    this.istrigering = false

    // shaking
    this.shaking = false
    this.shaking1 = false

    // current type
    this.currentType = 1

    this.playFlake = false

    this.playHistory = []
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    window.playChangeColor = () => {
      this.playChangeColor()
    }
    this.handerIndicator()

    if (this.game.config.width > this.game.config.height) {
      this.logo = this.add
        .sprite(this.game.config.height * 0.1, this.game.config.height * 0.07, "logo")
        .setDisplaySize(this.game.config.height * 0.15, this.game.config.height * 0.15)
    } else {
      this.logo = this.add
        .sprite(this.game.config.width * 0.1, this.game.config.height * 0.04, "logo")
        .setDisplaySize(this.game.config.width * 0.15, this.game.config.width * 0.15)
    }
    // gradian
    window.Play = (type) => {
      this.playParticleSpin = true
      this.switchComponent(type)
    }

    window.scaleAnimation = () => {
      this.scaleAnimation()
    }

    window.endGamehander = () => {
      this.endGamehander()
    }

    window.startCountDown = () => {
      this.startCountDown()
    }

    this.initLayout()

    // let playAgain = localStorage.getItem("playAgain")
    // if (playAgain === "true") {
    //   this.startCountDown()
    // }
  }

  handerIndicator() {
    let position = {
      x: this.game.config.width * 0.5,
      y: this.game.config.height * 0.11,
    }

    let size = {
      w: this.game.config.height * 0.15,
      h: this.game.config.height * 0.15,
    }

    this.indicator = this.add.sprite(0, 0, "redball").setOrigin(0.5).setDisplaySize(size.w, size.h).setPosition(position.x, position.y)
    this.indicator.name = "indicator"

    // draw a circle
    this.circularProgress = this.add.rexCircularProgress({
      x: position.x,
      y: position.y,
      radius: size.w * 0.55,
      thickness: 0.2,

      trackColor: 0xffffff,
      barColor: 0xb33321,
      value: 1,
    })

    this.circularProgress.setDepth(-1)

    this.circularProgress.name = "circularProgress"

    this.glowLight = this.add
      .sprite(0, 0, "redlight")
      .setOrigin(0.5)
      .setDisplaySize(size.w * 1.333, size.w * 1.333)
      .setPosition(position.x, position.y)
      .setAlpha(0)
      .setDepth(2)

    this.glowLight.name = "glowLight"

    this.gradient = null
    this.gradient2 = null
    this.addGradient()
  }

  endGamehander() {
    this.flakes = []
    let size = Math.pow(this.game.config.width * this.game.config.height * 0.02, 0.5)
    for (var i = 0; i < size; i++) {
      var f = new Flake(this)
      this.flakes.push(f)
    }

    this.playFlake = true
    this.sound.play("Ending")

    let sizeTimeup = {
      w: this.game.config.height * 0.15 * 2.596,
      h: this.game.config.height * 0.15,
    }
    this.timeup = this.add.sprite(this.game.config.width * 0.5, this.game.config.height * 0.5, "timeup")
    this.timeup.setDisplaySize(sizeTimeup.w, sizeTimeup.h).setDepth(100)

    this.tweens.add({
      targets: [this.timeup],
      duration: 1500,
      ease: "Bounce.easeOut",
      scale: (sizeTimeup.w / this.timeup.width) * 1.2,
    })
  }

  // init layout
  initLayout() {
    this.pointText = this.add
      .text(this.indicator.x, this.indicator.y + this.game.config.height * 0.12 * 0.9, "0", {
        fontFamily: "HelveticaLTPro-Bold",
        fontSize: this.game.config.height * 0.04,
        color: "#ffffff",
      })
      .setOrigin(0.5)
  }

  startCountDown() {
    this.countDown = this.add.sprite(this.game.config.width * 0.5, this.game.config.height * 0.5, "countdown")
    this.countDown.anims.playReverse("countdown").on("animationcomplete", () => {
      this.countDown.destroy()
      let phaserContainer = document.getElementById("phaser-container")
      phaserContainer.style.pointerEvents = "none"
      window.start()
    })
  }

  triggerSuperPower(e) {
    if (!this.istrigering) {
      this.istrigering = true
      this.super_1 = this.add.image(this.game.config.width * 0.5, 0, "superpower").setDepth(-1)
      this.super_1.setDisplaySize(this.game.config.width * 1.5, this.game.config.width * 0.75)
      this.super_1.setAlpha(0)

      this.super_2 = this.add.image(this.game.config.width * 0.5, this.game.config.height, "superpower").setDepth(-1)
      this.super_2.setDisplaySize(this.game.config.width * 1.5, this.game.config.width * 0.75)
      this.super_2.setAlpha(0)

      this.tweens.add({
        targets: [this.super_2, this.super_1],
        alpha: 1,
        duration: 3000,
      })
    }
  }

  addGradient() {
    this.gradient = this.add.sprite(0, -this.scale.height * 0.02, "gradient_red").setOrigin(0)
    this.gradient.setDisplaySize(this.scale.width, this.scale.height * 0.3)
    this.gradient.setDepth(-2)
    this.gradient.setAlpha(0)

    this.gradient2 = this.add.sprite(0, this.scale.height - this.scale.height * 0.3 + this.scale.height * 0.05, "gradient_red").setOrigin(0)
    this.gradient2.setDisplaySize(this.scale.width, this.scale.height * 0.3)
    this.gradient2.setDepth(-2)
    this.gradient2.setFlipY(true)
    this.gradient2.setAlpha(0)
  }

  animateGradient(currentType) {
    if (this.gradient && this.gradient2) {
      switch (currentType) {
        case 1:
          this.gradient.anims.play("gradient_red")
          this.gradient2.anims.play("gradient_red")
          break

        case 2:
          this.gradient.anims.play("gradient_yellow")
          this.gradient2.anims.play("gradient_yellow")
          break

        case 3:
          this.gradient.anims.play("gradient_green")
          this.gradient2.anims.play("gradient_green")
          break
        case 4:
          this.gradient.anims.play("gradient_blue")
          this.gradient2.anims.play("gradient_blue")
          break

        default:
          this.gradient.anims.play("gradient_blue")
          this.gradient2.anims.play("gradient_blue")
          break
      }

      this.tweens.add({
        targets: [this.gradient, this.gradient2],
        alpha: 1,
        ease: "Power4",
        duration: 1000,
        onComplete: () => {
          this.tweens.add({
            targets: [this.gradient, this.gradient2],
            alpha: 0,
            delay: 1000,
            duration: 500,
          })
        },
      })
    }
  }

  destroySuperPower() {
    if (this.super_1 && this.super_2) {
      this.istrigering = false
      this.tweens.add({
        targets: [this.super_2, this.super_1],
        alpha: 0,
        duration: 1000,
        onComplete: () => {
          this.super_1.destroy()
          this.super_2.destroy()
        },
      })
    }
  }

  scaleAnimation() {
    let size = {
      w: this.game.config.height * 0.15,
      h: this.game.config.height * 0.15,
    }

    if (!this.shaking && !this.shaking1 && !this.shaking2) {
      this.shaking = true
      this.shaking1 = true
      this.shaking2 = true

      this.tweens.add({
        targets: [this.indicator],
        displayWidth: size.w * 0.85,
        displayHeight: size.w * 0.85,
        duration: 100,
        ease: "Quad.easeInOut",
        yoyo: true,
        onComplete: () => {
          this.shaking = false
        },
      })

      this.tweens.add({
        targets: [this.circularProgress],
        radius: size.w * 0.85 * 0.5,
        duration: 100,
        ease: "Quad.easeInOut",
        yoyo: true,
        onComplete: () => {
          this.shaking1 = false
        },
      })

      this.tweens.add({
        targets: [this.glowLight],
        displayWidth: size.w * 0.85,
        displayHeight: size.w * 0.85,
        alpha: 1,
        duration: 100,
        ease: "Quad.easeInOut",
        yoyo: true,
        onComplete: () => {
          this.shaking2 = false
        },
      })
    }
  }

  switchComponent(currentType, isSuperPower) {
    let theme = document.getElementsByTagName("meta")["theme-color"]
    if (isSuperPower) {
      this.sound.play("PowerMode1")
    } else {
      this.sound.play("ChangePattern1")
    }
    this.playParticleSpin = true
    this.currentType = currentType

    if (this.indicator) {
      this.animateGradient(currentType)

      this.tweens.add({
        targets: [this.glowLight],
        alpha: 1,
        yoyo: true,
        duration: 1000,
        onComplete: () => {
          this.glowLight.setAlpha(0)
        },
      })

      switch (currentType) {
        case 1:
          this.circularProgress.setBarColor(0xe81111)
          this.indicator.anims.play("redball")
          this.glowLight.anims.play("redlight")
          theme.setAttribute("content", "#e81111")
          break
        case 2:
          this.indicator.anims.play("goldball")
          this.circularProgress.setBarColor(0xfdba0f)
          this.glowLight.anims.play("yellowlight")
          theme.setAttribute("content", "#fdba0f")
          break
        case 3:
          this.indicator.anims.play("greenball")
          this.circularProgress.setBarColor(0x1eae35)
          this.glowLight.anims.play("greenlight")
          theme.setAttribute("content", "#1eae35")
          break
        case 4:
          this.indicator.anims.play("blueball")
          this.circularProgress.setBarColor(0x198dfa)
          this.glowLight.anims.play("bluelight")
          theme.setAttribute("content", "#198dfa")
          break

        default:
          this.indicator.anims.play("blueball")
          this.circularProgress.setBarColor(0x198dfa)
          this.indicator.anims.play("bluelight")
          theme.setAttribute("content", "#198dfa")
          break
      }
    }
  }

  setEmitterPosition(e) {
    // play sound
    this.sound.play("snowpuff")

    this.playHistory.push(Date.now())

    // game point
    let _point = parseInt(this.pointText.text)
    this.pointText.setText(_point + 70)

    let _index = 0.06
    if (this.game.config.width > this.game.config.height) {
      _index = 0.1
    }

    // game width
    const r = this.game.config.height * _index * this.dpr * (1 - e.distance / 100)

    let snowSprites = new Array(70).fill(0).map((item, index) => {
      let random_l = Math.random()
      if (random_l < 0.35) {
        let _r = Math.random()
        if (_r < 0.3) {
          return this.add
            .image(0, 0, "snow" + (1).toString())
            .setOrigin(0.5)
            .setDisplaySize(this.scale.height * 0.01 * this.dpr, this.scale.height * 0.01 * this.dpr)
            .setPosition(e.x * this.dpr, e.y * this.dpr)
        } else {
          return this.add
            .image(0, 0, "snow" + (2).toString())
            .setOrigin(0.5)
            .setDisplaySize(this.scale.height * 0.01 * this.dpr, this.scale.height * 0.01 * this.dpr)
            .setPosition(e.x * this.dpr, e.y * this.dpr)
        }
      } else if (random_l < 0.75) {
        return this.add
          .image(0, 0, "snow" + (3).toString())
          .setOrigin(0.5)
          .setDisplaySize(this.scale.height * 0.03 * this.dpr, this.scale.height * 0.03 * this.dpr)
          .setPosition(e.x * this.dpr, e.y * this.dpr)
      } else {
        return this.add
          .image(0, 0, "snow" + (4).toString())
          .setOrigin(0.5)
          .setDisplaySize(this.scale.height * 0.03 * this.dpr, this.scale.height * 0.03 * this.dpr)
          .setPosition(e.x * this.dpr, e.y * this.dpr)
      }
    })

    snowSprites.forEach((item, index) => {
      try {
        let currentPos = new Vector2(item.x, item.y)
        let delta = (Math.PI * 2 * index) / snowSprites.length

        let targetPos = new Vector2(currentPos.x + Math.cos(delta) * r * 1.5, currentPos.y + Math.sin(delta) * r * Math.random() * 1.5)

        this.tweens.add({
          targets: item,
          x: targetPos.x,
          y: targetPos.y,
          duration: 1500,
          scale: 0.1,
          alpha: 0,
          ease: "Power4",
          onComplete: () => {
            item.destroy()
          },
        })
      } catch (err) {
        console.log(err)
      }
    })
  }

  speedOrnaments(e) {
    if (this.playParticleSpin) {
      let radius = this.game.config.height * 0.06
      let position = {
        x: this.game.config.width * 0.5,
        y: this.game.config.height * 0.11,
      }
      for (let i = 0; i < 5; i++) {
        let _x = Math.cos(this.count.value + i * 0.05) * radius + position.x
        let _y = Math.sin(this.count.value + i * 0.05) * radius + position.y

        let _p = this.add
          .image(_x, _y, "Pixel_E")
          .setDisplaySize(this.scale.height * 0.004 * this.dpr * Math.random(), this.scale.height * 0.004 * this.dpr * Math.random())
          .setAlpha(0.5)
        _p.liveTime = this.time.now
        _p.speedInfo = {
          x: Math.random() * 0.5 * (Math.random() > 0.5 ? 1.5 : -1.5),
          y: Math.random() * 0.5 * (Math.random() > 0.5 ? 1.5 : -1.5),
          rotation: Math.random() * 0.5 * (Math.random() > 0.5 ? 1.5 : 2.5),
        }
        this.particlegroup.push(_p)
      }

      this.particlegroup.forEach((item, index) => {
        if (this.time.now - item.liveTime > 1000) {
          item.destroy()
          this.particlegroup.splice(index, 1)
        }

        item.x += item.speedInfo.x
        item.y += item.speedInfo.y
        item.rotation += item.speedInfo.rotation
      })

      this.count.value += 0.15

      if (this.count.value > 4 * Math.PI) {
        this.count.value = 0
        this.playParticleSpin = false
      }
    } else if (this.particlegroup.length > 0) {
      this.particlegroup.forEach((item, index) => {
        if (this.time.now - item.liveTime > 1000) {
          item.destroy()
          this.particlegroup.splice(index, 1)
        }

        item.x += item.speedInfo.x
        item.y += item.speedInfo.y
        item.rotation += item.speedInfo.rotation
      })
    }
  }

  update(e) {
    this.speedOrnaments(e)

    if (this.playFlake) {
      this.flakes.forEach((f) => {
        try {
          f.move()
        } catch (err) {}
      })
    }
  }
}
